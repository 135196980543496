<template>
  <b-modal
    id="refund-form"
    :visible="shallShowRefundFormModal"
    :title="`${$t('Refund')}`"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    no-fade
    hide-backdrop
    static
    @change="(val) => $emit('update:shall-show-refund-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t('Form')} ${$t('Refund')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-refund-form-modal', false) : false"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ $t('Refund') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div
            class="mx-1"
            style="margin-top: 0.4em;"
          >
            <b-row class="m-0">

              <!-- Field: Brand -->
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  :label="$t('Brand')"
                  label-for="refund-brand"
                >
                  <b-badge
                    id="refund-brand"
                    pill
                    :variant="(skin === 'dark')? `light-secondary` : `secondary`"
                  >
                    {{ refundData.brand_name }}
                  </b-badge>
                </b-form-group>

              </b-col>

              <!-- Field: Merchant -->
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  :label="$t('Merchant')"
                  label-for="refund-merchant"
                >
                  <b-badge
                    id="refund-merchant"
                    pill
                    :variant="(skin === 'dark')? `light-secondary` : `secondary`"
                  >
                    {{ refundData.merchant_name }}
                  </b-badge>
                </b-form-group>

              </b-col>

              <!-- Field: Code -->
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  :label="$t('Code')"
                  label-for="refund-code"
                >
                  <b-badge
                    id="refund-code"
                    pill
                    :variant="(skin === 'dark')? `light-info` : `info`"
                  >
                    {{ refundData.code }}
                  </b-badge>
                </b-form-group>

              </b-col>

              <!-- Field: Value -->
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  :label="$t('Value')"
                  label-for="refund-value"
                >
                  <b-badge
                    id="refund-value"
                    pill
                    :variant="(skin === 'dark')? `light-success` : `success`"
                  >
                    ฿ {{ refundData.value }}
                  </b-badge>
                </b-form-group>

              </b-col>

            </b-row>

            <b-row class="m-0">

              <!-- Field: Note -->
              <b-col
                cols="12"
                md="9"
              >
                <b-form-group
                  :label="$t('Note')"
                  label-for="refund-note"
                >
                  <b-form-input
                    id="refund-note"
                    v-model="refundData.note"
                  />
                </b-form-group>

              </b-col>

              <!-- Field: New Redeem -->
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  :label="$t('New Redeem')"
                  label-for="refund-new-redeem"
                >
                  <b-form-checkbox
                    v-model="refundData.new_redeem"
                    class="custom-control-primary"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>

              </b-col>

            </b-row>

          </div>

        </b-form>

      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol,
  BOverlay,
  BProgress,
  BCardText,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  // ref,
  onUnmounted,
  // computed,
} from '@vue/composition-api'
import {
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import redeemStoreModule from '../redeemStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BProgress,
    BCardText,
    BBadge,
    BFormCheckbox,

    ValidationObserver,
  },
  model: {
    prop: 'shallShowRefundFormModal',
    event: 'update:shall-show-refund-form-modal',
  },
  props: {
    shallShowRefundFormModal: {
      type: Boolean,
      required: true,
    },
    refundData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      skin: store.state.appConfig.layout.skin,
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
    }
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      store.dispatch('redeem-store/refundRedeem', {
        redeem_id: this.refundData.id,
        note: this.refundData.note,
        new_redeem: this.refundData.new_redeem,
        user_id: this.userData.id,
      })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false

          this.$emit('refund-form-update')
          this.$emit('update:shall-show-refund-form-modal', false)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    onDiscard() {
      this.$emit('discard-refund-form')
      this.$emit('update:shall-show-refund-form-modal', false)
    },
  },
  setup() {
    const REDEEM_STORE_MODULE_NAME = 'redeem-store'

    // Register module
    if (!store.hasModule(REDEEM_STORE_MODULE_NAME)) store.registerModule(REDEEM_STORE_MODULE_NAME, redeemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REDEEM_STORE_MODULE_NAME)) store.unregisterModule(REDEEM_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getRedeems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ecoupon/get_redeems', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRedeem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ecoupon/get_redeem', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRedeem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ecoupon/edit_redeem/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRedeem(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ecoupon/delete_redeem/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultRedeem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ecoupon/get_default_redeem', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    refundRedeem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ecoupon/refund_redeem', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

<template>
  <div
    v-if="$can('read', 'Coupon')"
    style="height: inherit"
  >

    <!-- Filters -->
    <redeem-list-filters
      :brand-filter.sync="brandFilter"
      :merchant-filter.sync="merchantFilter"
      :code-filter.sync="codeFilter"
      :refid-filter.sync="refidFilter"
      :brand-options="brandOptions"
      :merchant-options="merchantOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-model="checkSelected"
              class="custom-control-primary"
              switch
              @change="changeSelect"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
            <label>{{ $t('Entries') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="`${$t('Search')}...`"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refRedeemListTable"
        :items="getRedeems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        hover
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        selectable
        select-mode="multi"
        @row-selected="onRowSelected"
      >

        <!-- Column: Date/Time -->
        <template #cell(date_time)="data">
          <div>
            <b-badge
              pill
              :variant="(skin === 'dark')? `light-info` : `info`"
            >
              {{ data.item.redeem_date }}
            </b-badge>
            <b-badge
              pill
              :variant="(skin === 'dark')? `light-info` : `info`"
              style="margin-left: 0.4em;"
            >
              {{ data.item.redeem_time }}
            </b-badge>
          </div>
          <div
            style="margin-top: 0.6rem;"
          >
            <b-list-group horizontal="md">
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 3rem;"
              >
                <small>{{ `${$t('By')}` }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <small>{{ (data.item.redeem_username !== '')? data.item.redeem_username : '-' }}</small>
              </b-list-group-item>
            </b-list-group>
            <b-list-group
              style="margin-top: 0.2rem;"
              horizontal="md"
            >
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 3rem;"
              >
                <small>{{ `${$t('ID')}` }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <small>#{{ (data.item.id !== '')? data.item.id : '-' }}</small>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>

        <!-- Column: Brand/Merchant/Coupon -->
        <template #cell(coupon)="data">
          <div>
            <b-list-group>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span class="align-text-top">{{ data.item.brand_name }}</span>
              </b-list-group-item>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span class="align-text-top">{{ data.item.merchant_name }}</span>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div style="margin-top: 0.6em;">
            <b-badge
              pill
              :variant="(skin === 'dark')? `light-info` : `info`"
            >
              {{ data.item.code }}
            </b-badge>
          </div>
          <div style="margin-top: 0.6em;">
            <b-badge
              pill
              :variant="(skin === 'dark')? `light-success` : `success`"
            >
              ฿ {{ data.item.value }}
            </b-badge>
          </div>
        </template>

        <!-- Column: RefID/Reference/Point -->
        <template #cell(refid_reference_point)="data">
          <div>
            <b-list-group horizontal="md">
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 6rem;"
              >
                <small>{{ `${$t('Ref ID')}` }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <b-badge
                  pill
                  :variant="(skin === 'dark')? `light-danger` : `danger`"
                >
                  {{ (data.item.ref_id !== '')? data.item.ref_id : '' }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>
            <b-list-group
              style="margin-top: 0.2rem;"
              horizontal="md"
            >
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 6rem;"
              >
                <small>{{ `${$t('Reference')}` }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <b-badge
                  pill
                  :variant="(skin === 'dark')? `light-danger` : `danger`"
                >
                  {{ (data.item.reference !== '')? data.item.reference : '' }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>
            <b-list-group
              style="margin-top: 0.2rem;"
              horizontal="md"
            >
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 6rem;"
              >
                <small>{{ `${$t('Point')}` }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <b-badge
                  pill
                  :variant="(skin === 'dark')? `light-danger` : `danger`"
                >
                  {{ (data.item.point !== '')? data.item.point : '' }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>

        <!-- Column: Refund/Date Time/Username -->
        <template #cell(refund_datetime_username)="data">
          <div v-if="!!data.item.refund">
            <b-badge
              pill
              :variant="(skin === 'dark')? `light-warning` : `warning`"
            >
              {{ (data.item.refund !== undefined && data.item.refund.date !== undefined)?
                data.item.refund.date : '' }}
            </b-badge>
            <b-badge
              pill
              :variant="(skin === 'dark')? `light-warning` : `warning`"
              style="margin-left: 0.4em;"
            >
              {{ (data.item.refund !== undefined && data.item.refund.time !== undefined)?
                data.item.refund.time : '' }}
            </b-badge>
          </div>
          <div
            v-if="!!data.item.refund"
            style="margin-top: 0.6rem;"
          >
            <b-list-group
              style="margin-top: 0.2rem;"
              horizontal="md"
            >
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 3rem;"
              >
                <small>{{ `${$t('By')}` }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                {{ (data.item.refund !== undefined && data.item.refund.username !== undefined)?
                  data.item.refund.username : '' }}
              </b-list-group-item>
            </b-list-group>
            <b-list-group
              style="margin-top: 0.2rem;"
              horizontal="md"
            >
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 3rem;"
              >
                <small>{{ `${$t('ID')}` }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                {{ (data.item.refund !== undefined && data.item.refund_id !== undefined && data.item.refund_id > 0)?
                  `#${data.item.refund_id}` : '' }}
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">

            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              style="margin-left: -10px;"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="(data.item.refund_id === '0')"
                @click="refundRedeemForm(data)"
              >
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">{{ $t('Refund') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="editRedeemForm(data)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="deleteRedeemForm(data)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('Showing {from} to {to} of {of} entries',
                    {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRedeems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <refund-form
      v-model="shallShowRefundFormModal"
      :refund-data="refundData"
      @refund-form-update="updateRefundForm"
      @discard-refund-form="discardRefundForm"
    />

    <redeem-edit-form
      v-model="shallShowRedeemEditFormModal"
      :redeem-data="redeemData"
      @redeem-edit-form-update="updateRedeemEditForm"
      @discard-redeem-edit-form="discardRedeemEditForm"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable,
  BBadge, BPagination, BFormCheckbox, BListGroup, BListGroupItem,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import RedeemListFilters from './RedeemListFilters.vue'
import useRedeemList from './useRedeemList'
import redeemStoreModule from '../redeemStoreModule'
import RefundForm from './RefundForm.vue'
import RedeemEditForm from './RedeemEditForm.vue'

export default {
  components: {
    RedeemListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,

    vSelect,
    FeatherIcon,
    RefundForm,
    RedeemEditForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      defaultData: {},
      dialog: false,
      skin: store.state.appConfig.layout.skin,
      selected: [],
      checkSelected: false,
    }
  },
  mounted() {
    this.getDefaultRedeem()
  },
  methods: {
    getDefaultRedeem() {
      store
        .dispatch('redeem-store/getDefaultRedeem', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.defaultData = response.data
          this.brandOptions = this.defaultData.brand_options
          this.merchantOptions = this.defaultData.merchant_options
          this.valueOptions = this.defaultData.value_options
          this.statusOptions = this.defaultData.status_options
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    onRowSelected(items) {
      this.selected = items
      if (this.selected.length > 0) {
        this.checkSelected = true
      } else {
        this.checkSelected = false
      }
    },
    changeSelect() {
      if (this.checkSelected === true) {
        this.$refs.refRedeemListTable.selectAllRows()
      } else {
        this.$refs.refRedeemListTable.clearSelected()
      }
    },
    editRedeemForm(data) {
      this.redeemData = data.item
      this.shallShowRedeemEditFormModal = true
    },
    refundRedeemForm(data) {
      this.refundData = data.item
      this.refundData.reference = ''
      this.refundData.new_redeem = true
      this.refundData.note = ''
      this.shallShowRefundFormModal = true
    },
    deleteRedeemForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('redeem-store/deleteRedeem', data.item.id)
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateRefundForm() {
      this.resetRefundData()
      this.refetchData()
    },
    discardRefundForm() {
      this.resetRefundData()
      this.refetchData()
    },
    updateRedeemEditForm() {
      this.resetRedeemEditData()
      this.refetchData()
    },
    discardRedeemEditForm() {
      this.resetRedeemEditData()
      this.refetchData()
    },
  },
  setup() {
    const shallShowRefundFormModal = ref(false)
    const shallShowRedeemEditFormModal = ref(false)

    const STORE_MODULE_NAME = 'redeem-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, redeemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const brandOptions = []

    const merchantOptions = []

    const {
      getRedeems,
      tableColumns,
      perPage,
      currentPage,
      totalRedeems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRedeemListTable,
      refetchData,

      // Extra Filters
      brandFilter,
      merchantFilter,
      codeFilter,
      refidFilter,
    } = useRedeemList()

    const filterRedeemList = JSON.parse(localStorage.getItem('filterRedeemList'))
    if (filterRedeemList !== null) {
      searchQuery.value = filterRedeemList.searchQuery
      perPage.value = filterRedeemList.perPage
      currentPage.value = filterRedeemList.page
      sortBy.value = filterRedeemList.sortBy
      isSortDirDesc.value = filterRedeemList.sortDesc
      brandFilter.value = filterRedeemList.brand
      merchantFilter.value = filterRedeemList.merchant
      codeFilter.value = filterRedeemList.code
      refidFilter.value = filterRedeemList.refid
    }

    const blankRefund = {
      brand_name: '',
      merchant_name: '',
      code: '',
      value: '',
      reference: '',
      note: '',
      new_redeem: true,
    }
    const refundData = ref(JSON.parse(JSON.stringify(blankRefund)))
    const resetRefundData = () => {
      refundData.value = JSON.parse(JSON.stringify(blankRefund))
    }

    const blankRedeem = {}
    const redeemData = ref(JSON.parse(JSON.stringify(blankRedeem)))
    const resetRedeemEditData = () => {
      redeemData.value = JSON.parse(JSON.stringify(blankRedeem))
    }

    return {
      shallShowRefundFormModal,
      shallShowRedeemEditFormModal,
      getRedeems,
      tableColumns,
      perPage,
      currentPage,
      totalRedeems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRedeemListTable,
      refetchData,

      brandOptions,
      merchantOptions,

      // Extra Filters
      brandFilter,
      merchantFilter,
      codeFilter,
      refidFilter,

      refundData,
      resetRefundData,

      redeemData,
      resetRedeemEditData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.redeem-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/e-coupon/redeem.scss";
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
      .swal2-popup {
        background-color: $theme-dark-body-bg !important;
      }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>

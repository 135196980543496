<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('Filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- Brand -->
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('Brand') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="brandFilter"
            :options="brandOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:brandFilter', val)"
          />
        </b-col>
        <!-- Merchant -->
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('Merchant') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="merchantFilter"
            :options="merchantOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:merchantFilter', val)"
          />
        </b-col>
        <!-- Code -->
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('Coupon Code') }}</label>
          <b-form-input
            :value="codeFilter"
            class="d-inline-block mr-1"
            :reduce="val => val.value"
            @input="(val) => $emit('update:codeFilter', val)"
          />
        </b-col>
        <!-- Ref ID -->
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('Reference ID') }}</label>
          <b-form-input
            :value="refidFilter"
            class="d-inline-block mr-1"
            :reduce="val => val.value"
            @input="(val) => $emit('update:refidFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormInput,
  },
  props: {
    brandFilter: {
      type: [String, null],
      default: null,
    },
    merchantFilter: {
      type: [String, null],
      default: null,
    },
    codeFilter: {
      type: [String, null],
      default: null,
    },
    refidFilter: {
      type: [String, null],
      default: null,
    },
    brandOptions: {
      type: Array,
      required: true,
    },
    merchantOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
